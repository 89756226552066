import React from 'react'
import Container from 'react-bootstrap/esm/Container';

let Docs = () => {

    return (
        <Container fluid>
            
        </Container>
    )
};

export default Docs;