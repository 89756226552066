import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown'
import { LinkContainer } from "react-router-bootstrap";
import React from 'react'

export default function MonitorNav() {

    return (
        <Nav>
            <LinkContainer to="/monitors">
                <Nav.Link>Monitors</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/integrations">
                <Nav.Link>Integrations</Nav.Link>
            </LinkContainer>
        </Nav>
    )
}