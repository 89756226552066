import React from 'react'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import moment from 'moment'
import HealthcheckEdit from './HealthcheckEdit'
import Table from 'react-bootstrap/Table';
import StatusBadge from './HealthMonStatusBadge';
import { BadgeStatus, calulateBadgeStatus } from './HealthMonStatusBadge'
import { getIconForIntType } from './Integrations'

type HealthcheckShowState = {
    id: string
    check: any
    pending: boolean
    integrations: []
    intChecks: any
    showEditModal: boolean
    events: [],
    badgeStatus: BadgeStatus
}

export default class HealthCheckShow extends React.Component<{}, HealthcheckShowState> {

    constructor(props: any) {
        super(props)
        this.deleteMon = this.deleteMon.bind(this);
        this.disableInt = this.disableInt.bind(this);
        this.editCheck = this.editCheck.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.state = { id: props.match.params.id, check: null, pending: true, integrations: [], intChecks: null, showEditModal: false, events: [], badgeStatus: BadgeStatus.UP }
    }

    async disableInt(val: any) {
        console.log(val);
        let result = await fetch(new Request(process.env.REACT_APP_API_URL + "/api/integrations/" + val.id + "/healthcheck/" + this.state.id, {
            credentials: 'include',
            method: "delete",
            body: null,
        }));
        if (result.ok) {
            let intChecks = this.state.intChecks;
            intChecks[val.id].status = "DISABLED";
            this.setState({ intChecks: intChecks })
        } else {
            alert(await result.text());
        }
    }

    async enableInt(val: any) {
        console.log(val);
        let result = await fetch(new Request(process.env.REACT_APP_API_URL + "/api/integrations/" + val.id + "/healthcheck/" + this.state.id, {
            credentials: 'include',
            method: "post",
            body: null,
        }));
        if (result.ok) {
            let intChecks = this.state.intChecks;
            if (intChecks[val.id]) {
                intChecks[val.id].status = "ENABLED";
                this.setState({ intChecks: intChecks });
            } else {
                await this.setCheckInts();
            }
        } else {
            alert(await result.text());
        }
    }

    async deleteMon() {
        //@ts-ignore
        const ok = window.confirm("Are you sure you want to delete this monitor? This is an unrecoverable operations.")
        if (!ok) {
            return;
        }
        let result = await fetch(new Request(process.env.REACT_APP_API_URL + "/api/healthchecks/" + this.state.id, {
            credentials: 'include',
            method: "delete",
            body: null,
        }));
        if (result.status === 400) {
            alert(await result.text())
        } else if (result.status === 500) {
            alert("An unknown error occurred. Please try again.")
        } else {
            //@ts-ignore
            this.props.history.push('/monitors')
        }
    }

    editCheck(event: any) {
        event.preventDefault();
        this.setState({ showEditModal: true })
    }

    async setCheckInts(): Promise<void> {
        let intChecksProm = fetch(process.env.REACT_APP_API_URL + "/api/integrations/healthcheck/" + this.state.id, { credentials: 'include' }).then((result) => {
            if (result.ok) {
                if (result.ok) {
                    result.json().then((body) => {
                        let intCheck = {};
                        body.forEach((element: any) => {
                            //@ts-ignore
                            intCheck[element.integrationId] = element
                        });
                        this.setState({ intChecks: intCheck })
                    })

                }
            }
        });
        return intChecksProm;
    }
    async componentDidMount() {
        let prom = fetch(process.env.REACT_APP_API_URL + "/api/healthchecks/" + this.state.id, { credentials: 'include' }).then((result) => {
            if (result.ok) {
                if (result.ok) {
                    result.json().then((body) => {
                        let status = calulateBadgeStatus(body)
                        this.setState({ check: body, badgeStatus: status })
                    })

                }
            }
        });

        let intsProm = fetch(process.env.REACT_APP_API_URL + "/api/integrations", { credentials: 'include' }).then((result) => {
            if (result.ok) {
                result.json().then((ints) => {
                    this.setState({ integrations: ints.integrations });
                })
            }
        })

        let eventsProm = fetch(process.env.REACT_APP_API_URL + "/api/healthchecks/" + this.state.id + "/events", { credentials: 'include' }).then((result) => {
            if (result.ok) {
                if (result.ok) {
                    result.json().then((body) => {
                        this.setState({ events: body })
                    })

                }
            }
        });

        let intChecksProm = this.setCheckInts();

        await Promise.all([prom, intsProm, intChecksProm, eventsProm])
        this.setState({ pending: false })
    }

    hideModal() {
        this.setState({ showEditModal: false })
    }

    render() {

        let ints = this.state.integrations.map((val: any) => {
            var enabled = false
            if (this.state.intChecks && this.state.intChecks[val.id]) {
                enabled = this.state.intChecks[val.id].status === "ENABLED";
            }
            var button = <Button size="sm" onClick={this.enableInt.bind(this, val)} variant="primary">+</Button>
            if (enabled) {
                button = <Button size="sm" onClick={this.disableInt.bind(this, val)} variant="danger">x</Button>
            }

            return <li key={val.id}> {getIconForIntType(val.type)} {val.name}  {button}</li>
        });

        let events = this.state.events.slice(0, 20).map((val: any) => {
            if (val.eventType === "PING") {
                return <tr key={val.id}><td>{moment(val.eventTime).format("MMM DD HH:mm ZZ")}</td>
                    <td><Badge pill variant="success">OK</Badge></td>
                    <td>Ping from {val.clientIp}</td><td>{val.userAgent ? "(" + val.userAgent + ")" : null}</td></tr>
            }
            return null;
        });

        if (this.state.check) {
            var grace = moment.duration(this.state.check.gracePeriod, 'minutes')
            return (<div>

                <Container fluid>
                    <Row>
                        <Col>

                            <h2>{this.state.check.name}</h2>
                            <a href="#" onClick={this.editCheck}>(edit...)</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <div>
                                <h4>How to ping</h4>
                            </div>

                            <p>You can keep this monitor up by making a GET or POST request to the following url:</p>
                            <pre><code>https://ping.healthmon.io/{this.state.id}</code></pre>
                            <h4>Status</h4>
                            <p>This check is currently: <StatusBadge badgeStatus={this.state.badgeStatus} pill={true} /></p>
                            <h4>Schedule</h4>
                            <Col className="schedule-col">
                                <Row><span>Cron String: {this.state.check.scheduleCron}</span> </Row>

                                <Row><span>Grace Period: {this.state.check.gracePeriod} minutes (~{grace.humanize()})</span> </Row>
                                <Row>Expected Ping Time: {this.state.check.lastPing ? moment(this.state.check.nextExpectedPing).fromNow() : "waiting for first ping"}</Row>
                            </Col>
                            <h4>Integrations</h4>
                            <p>Hit the blue + to enable an integration or the red x to disable.</p>
                            <ul className="none-list">
                                {ints}
                            </ul>
                            <Button size="sm" variant="danger" onClick={this.deleteMon}>Delete Monitor</Button>
                        </Col>
                        <Col md="1"></Col>
                        <Col md="auto">
                            <h3>Events</h3>
                            <Table size="sm" responsive >
                                <thead>

                                </thead>
                                <tbody>
                                    {events}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                </Container>
                <HealthcheckEdit closeCBFunc={this.hideModal} check={this.state.check} showAddBtn={false} show={this.state.showEditModal} />
            </div>)
        } else if (this.state.pending === false) {

            return (
                <Container>
                    <h3>Could not find check with id: {this.state.id}</h3>
                </Container>
            )
        } else {
            return (<Container fluid><h3>Loading...</h3></Container>)
        }


    }
}