import React from 'react';
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { MdMailOutline, MdAlarm } from 'react-icons/md'
import { GiAcousticMegaphone } from 'react-icons/gi'
import IntegrationEdit from './IntegrationEdit';
import Button from 'react-bootstrap/Button'

type HealthcheckState = {
    integrations: any[],
    showEditModal: boolean,
    validated: boolean

}

let getIconForIntType = function (type: string) {
    if (type === "EMAIL") {
        return <MdMailOutline />
    } else if (type === "SHOUTRRR") {
        return <GiAcousticMegaphone />
    }
    return <MdAlarm />
};

export { getIconForIntType }

class Integrations extends React.Component<{}, HealthcheckState> {

    constructor(props: Readonly<{}>) {
        super(props)
        this.state = { integrations: [], showEditModal: false, validated: false }
    }


    async componentDidMount() {

        let prom = await fetch(process.env.REACT_APP_API_URL + "/api/integrations", { credentials: 'include' });
        if (prom.status === 200) {
            let checks = await prom.json();
            this.setState({ integrations: checks.integrations });
        }
    }

    getIntegrations() {
        return [
            <tr key="email"><td></td><td>Email</td><td><IntegrationEdit type="EMAIL"></IntegrationEdit></td></tr>,
            <tr key="discord"><td></td><td>Discord</td><td><IntegrationEdit type="SHOUTRRR"></IntegrationEdit></td></tr>,
            <tr key="telegram"><td></td><td>Telegram</td><td><IntegrationEdit type="SHOUTRRR"></IntegrationEdit></td></tr>,
            <tr key="pushover"><td></td><td>Pushover</td><td><IntegrationEdit type="SHOUTRRR"></IntegrationEdit></td></tr>,
            <tr key="slack"><td></td><td>Slack</td><td><IntegrationEdit type="SHOUTRRR"></IntegrationEdit></td></tr>,
            <tr key="ms-team"><td></td><td>Microsoft Teams</td><td><IntegrationEdit type="SHOUTRRR"></IntegrationEdit></td></tr>,
            <tr key="gotify"><td></td><td>Gotify</td><td><IntegrationEdit type="SHOUTRRR"></IntegrationEdit></td></tr>,
            <tr key="pushbullet"><td></td><td>Pushbullet</td><td><IntegrationEdit type="SHOUTRRR"></IntegrationEdit></td></tr>,
            <tr key="ifttt"><td></td><td>IFTTT</td><td><IntegrationEdit type="SHOUTRRR"></IntegrationEdit></td></tr>,
            <tr key="mattermost"><td></td><td>Mattermost</td><td><IntegrationEdit type="SHOUTRRR"></IntegrationEdit></td></tr>,
            <tr key="hangoutschat"><td></td><td>Hangouts Chat</td><td><IntegrationEdit type="SHOUTRRR"></IntegrationEdit></td></tr>,
        ]
    }

    async deleteIntegration(id: any) {
        let ok = window.confirm("Are you sure you want to delete this integration? You can recreate it later if you need to")
        if (!ok) {
            return
        }
        let resp = await fetch(new Request(process.env.REACT_APP_API_URL + "/api/integrations/" + id, {
            credentials: 'include',
            method: "delete"
        }))
        if (resp.ok) {
            let ints = this.state.integrations.filter((val: any) => { return val.id !== id; });
            this.setState({ integrations: ints });
        } else {
            if (resp.status === 400) {
                alert(await resp.text())
            } else {
                alert("Failed to delete integration. Please retry or reach out to support");
            }
        }
    }

    render() {
        let now = new Date();
        let self = this;
        let output = this.state.integrations.map(function (check: any) {
            return <tr key={check.id}>
                <td>{getIconForIntType(check.type)}</td>
                <td>{check.name}</td>
                <td>{check.verified ? "Ready" : "Verification Needed"}</td>
                <td><Button size="sm" variant="danger" onClick={self.deleteIntegration.bind(self, check.id)}>Delete</Button></td>
            </tr>;
        });
        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col md="auto">
                            <p>Below are all your created notification methods. For added security, we hide the current value of the method. However,
                                you can edit/delete existing ones or create new ones as needed.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Table borderless responsive size="sm">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {output}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Add more notification methods</h3>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Table borderless responsive size="sm">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {this.getIntegrations()}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                </Container>

            </div>
        )
    }
}

export default Integrations;