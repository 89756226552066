import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Form from 'react-bootstrap/Form'
import { useFormik } from 'formik';


interface IntegrationEditProps {
    existingInt?: any
    closeModalCB?: any
    show?: boolean
    type?: string
}

let getInfo = (props: IntegrationEditProps) => {
    if (props.type !== "SHOUTRRR") {
        return null;
    }
    return (<p>
        We use <a href="https://containrrr.dev/shoutrrr/services/overview/">Shoutrrr</a> to send notifications for this integration. See their
        <a href="https://containrrr.dev/shoutrrr/services/overview/"> docs</a> on the value format depending on method you want to use.
    </p>)
}

let getValueLabel = (props: IntegrationEditProps) => {
    if (props.type !== "EMAIL") {
        return "Value";
    }
    return "Email Address"
}

let getValueType = (props: IntegrationEditProps) => {
    if (props.type !== "EMAIL") {
        return "tex";
    }
    return "email"
}



let IntegrationEdit = (props: IntegrationEditProps) => {
    const [show, setShow] = useState(props.show);

    const closeModal = () => {
        setShow(false)
        if (props.closeModalCB) {
            props.closeModalCB();
        }
    }

    const showModal = () => {
        setShow(true)
    }

    const formik = useFormik({
        initialValues: {
            value: props.existingInt ? props.existingInt.value : "",
            name: props.existingInt ? props.existingInt.name : "",
            type: props.type
        },
        onSubmit: async (values) => {
            console.log(values);
            if (props.existingInt) {

            } else {

                let resp = await fetch(new Request(process.env.REACT_APP_API_URL + "/api/integrations", {
                    credentials: 'include',
                    method: "post",
                    body: JSON.stringify(values)
                }));
                if (resp.ok) {
                    window.location.reload();
                } else if (resp.status === 400) {
                    alert("Failed to add integration: " + await resp.text())
                } else {
                    alert("Failed to add integration")
                }
            }

        }
    })



    return (
        <div>
            <Button size="sm" variant="success" onClick={showModal}>Add Integration</Button>

            <Modal show={show} onHide={closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title>{props.existingInt ? "Edit" : "Add"} Integration</Modal.Title>
                </Modal.Header>


                <Form onSubmit={formik.handleSubmit as any}>
                    <Modal.Body>
                        {getInfo(props)}

                        <Form.Group controlId="intNameEdit">
                            <Form.Label>Name</Form.Label>
                            <Form.Control name="name" onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={formik.values.name} required type="text" maxLength={100} placeholder="Enter name" />
                        </Form.Group>
                        <Form.Group controlId="intValueEdit">
                            <Form.Label>{getValueLabel(props)}</Form.Label>
                            <Form.Control name="value" onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={formik.values.value} required type={getValueType(props)} maxLength={1000} placeholder="Enter value" />
                        </Form.Group>

                    </Modal.Body>
                    <ModalFooter>
                        <Button variant="secondary" onClick={closeModal}>Cancel</Button>
                        <Button disabled={formik.isSubmitting} type="submit" variant="primary">Save changes</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    )
}

export default IntegrationEdit