import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

let About = () => {

    return (
        <Container fluid>
            <Row>
                <Col md="auto">
                    <h3>Contact</h3>
                    <p>If you have any questions or need any assistance using our service, please send us an email or message us through chat</p>
                    <p>Email: <a href="mailto:support@healthmon.io">support@healthmon.io</a></p>
                    <p>Discord: <a href="https://discord.gg/tnW4gjk">https://discord.gg/tnW4gjk</a></p>
                    <h3>Uptime SLA</h3>
                    <p>Healthmon is currently hosted in <a href="https://aws.amazon.com/">AWS</a> in the us-west-2 datacenter in Oregon. This website along with the ping endpoints are distributed
            across multiple availability zones to increase reliability during an outage. The database servers are in a similar configuration with automatic failover to a hot standby
            in case of a failure in the primary that completes within minutes of failure. Additionally, we have multiple alerts to monitor the health of the system and send notifications
            to investigate in case of issues. In practice, we expect our uptime to be multiple nines of availability and you can see our uptime in the badge below. While all accounts with us are on a best effort SLA with regards to usage of the service,
            please do contact us if you are unhappy with the service for any reason, and we will be sure to assist you.    </p>
                    <p> <a href="https://www.sixnines.io/h/8944"><img src="https://www.sixnines.io/b/8944?style=flat" alt="ping.healthmon.io availability badge" width="106" height="20"></img></a></p>
                </Col>
            </Row>
            <Row>
                <Col md="auto">
                    <h4>Attributions</h4>
                    <div>We'd like to thank <a href="https://www.flaticon.com/authors/itim2101" title="itim2101">itim2101</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> for making our icon</div>
                </Col>
            </Row>
        </Container>
    )
};

export default About;