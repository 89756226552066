import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from './monitor.svg';
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Route } from "react-router-dom";
import MonitorNav from './MonitorNav';
import { LinkContainer } from 'react-router-bootstrap';

type AppNavProps = {
    name: string,
}

class AppNav extends React.Component<AppNavProps, {}> {
    constructor(props: Readonly<AppNavProps>) {
        super(props)
    }

    async logout() {
        localStorage.removeItem("hc-user")
        window.location.href = (process.env.REACT_APP_API_URL + "/api/logout");
    }


    render() {
        let greet;
        let dropLink;
        if (this.props.name) {
            greet = this.props.name;
            dropLink = <NavDropdown title={greet} id="basic-nav-dropdown-2">
                <Nav.Link onClick={this.logout} href="#">Logout</Nav.Link>
            </NavDropdown>;
        } else {
            let url = process.env.REACT_APP_API_URL + "/api/login";
            dropLink = <Nav.Link href={url}>Login</Nav.Link>
        }

        return (

            < div className="nav-bot" >
                <Navbar bg="light" expand="lg">
                    <Nav>
                        <img className="nav-logo" src={logo}></img>

                        <Navbar.Brand>
                            <LinkContainer to="/">
                                <Nav.Link>HealthMon</Nav.Link>
                            </LinkContainer>
                        </Navbar.Brand>
                    </Nav>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    {
                        this.props.name ?
                            <>
                                <Route path="/monitors" component={MonitorNav} />
                                <Route path="/integrations" component={MonitorNav} />
                            </> : null
                    }

                    <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
                        <Nav>

                            <LinkContainer to="/uptimemon">
                                <Nav.Link>Uptime Monitoring</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/monitors">
                                <Nav.Link>Cron Monitoring</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/pricing">
                                <Nav.Link>Pricing</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/docs">
                                <Nav.Link>Docs</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/about">
                                <Nav.Link>About</Nav.Link>
                            </LinkContainer>
                            {dropLink}
                        </Nav>

                    </Navbar.Collapse>
                </Navbar>

            </div >
        )
    }
}

export default AppNav