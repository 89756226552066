import Badge from 'react-bootstrap/Badge';
import React from 'react';
import moment from 'moment'

enum BadgeStatus {
    "UP",
    "INITIAL",
    "DOWN",
    "GRACE",
    "UNKNOWN"
}

interface BadgeProps {
    badgeStatus: BadgeStatus,
    pill?: boolean
}

let calulateBadgeStatus = (body: any): BadgeStatus => {
    let now = moment();
    let alertTime = moment(body.nextAlertTime);
    let pingTime = moment(body.nextExpectedPing);
    var status: BadgeStatus = BadgeStatus.UP;
    if (body.alertStatus === "INITIAL") {
        status = BadgeStatus.INITIAL;
    } else if (now.isAfter(alertTime)) {
        status = BadgeStatus.DOWN;
    } else if (now.isBetween(pingTime, alertTime)) {
        status = BadgeStatus.GRACE;
    } else if (now.isBefore(pingTime)) {
        status = BadgeStatus.UP
    }
    else {
        status = BadgeStatus.UNKNOWN;
    }
    return status
}

let StatusBadge = (props: BadgeProps) => {
    var status = null;
    if (props.badgeStatus === BadgeStatus.DOWN) {
        status = <Badge pill={props.pill} variant="danger">Down</Badge>
    } else if (props.badgeStatus === BadgeStatus.UP) {
        status = <Badge pill={props.pill} variant="success">Up</Badge>
    } else if (props.badgeStatus === BadgeStatus.INITIAL) {
        status = <Badge pill={props.pill} variant="secondary">Initial</Badge>
    } else if (props.badgeStatus === BadgeStatus.GRACE) {
        status = <Badge pill={props.pill} variant="warning">Grace</Badge>
    } else {
        status = <Badge pill={props.pill} variant="secondary">Unknown</Badge>
    }
    return status;
};

export default StatusBadge
export { calulateBadgeStatus, BadgeStatus }