import React from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Formik } from 'formik';
import Container from 'react-bootstrap/Container';

type EditState = {
    show: boolean,
    validated: boolean
    check?: Healthcheck
}

type EditProps = {
    id?: string,
    show: boolean
    check?: Healthcheck
    showAddBtn: boolean
    closeCBFunc: Function
}

interface Healthcheck {
    id?: string
    name?: string;
    scheduleCron?: string;
    gracePeriod?: number;
}

export default class HealthcheckEdit extends React.Component<EditProps, EditState> {
    // @ts-ignore
    //state = { id: null, show: false, validated: false }

    constructor(props: EditProps) {
        super(props)
        this.state = { show: props.show, check: props.check, validated: false }
        this.handleModal = this.handleModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps: EditProps) {
        if (this.props.show && !this.state.show) {
            this.setState({ show: this.props.show })
        }
    }

    closeModal() {
        this.setState({ show: false })
        this.props.closeCBFunc();
    }

    handleModal(event: any) {
        this.setState({ show: true })
    }

    async handleSubmit(values: Healthcheck, { setSubmitting }: any) {
        if (this.props.check) {
            console.log("editing check!");
            let result = await fetch(new Request(process.env.REACT_APP_API_URL + "/api/healthchecks", {
                credentials: 'include',
                method: "put",
                body: JSON.stringify({
                    id: this.state.check?.id,
                    ...values
                })
            }));
            if (result.ok) {
                window.location.reload();
            } else if (result.status === 400) {
                alert(await result.text())
            } else {
                alert("An unknown error occurred. Please try again.")
            }
        } else {
            let result = await fetch(new Request(process.env.REACT_APP_API_URL + "/api/healthchecks", {
                credentials: 'include',
                method: "post",
                body: JSON.stringify(values)
            }));
            if (result.status === 400) {
                alert(await result.text())
            } else if (result.status === 500) {
                alert("An unknown error occurred. Please try again.")
            } else {
                window.location.reload();
            }
        }

        setSubmitting(false);
    };

    render() {

        return (
            <Container fluid>
                {this.props.showAddBtn ?
                    <Button variant="success" onClick={this.handleModal}>Add Monitor</Button> :
                    null
                }

                <Modal show={this.state.show} onHide={this.closeModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.check ? "Edit" : "Create"} Monitor</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{ name: this.state?.check?.name, scheduleCron: this.state?.check?.scheduleCron, gracePeriod: this.state?.check?.gracePeriod }}
                        validate={values => { return {}; }}
                        onSubmit={this.handleSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (

                            <Form onSubmit={

                                handleSubmit as any
                            }>
                                <Modal.Body>

                                    <Form.Group controlId="formName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control name="name" onChange={handleChange} onBlur={handleBlur}
                                            value={values.name} required type="text" maxLength={100} placeholder="Enter name" />
                                    </Form.Group>

                                    <Form.Group controlId="formScheduleCron">
                                        <Form.Label>Schedule (cron)</Form.Label>
                                        <Form.Control name="scheduleCron" onChange={handleChange} onBlur={handleBlur}
                                            value={values.scheduleCron} required type="text" placeholder="Cron string" />
                                    </Form.Group>
                                    <Form.Group controlId="formGracePeriod">
                                        <Form.Label>Grace Period (Minutes)</Form.Label>
                                        <Form.Control name="gracePeriod" onChange={handleChange} onBlur={handleBlur}
                                            value={values.gracePeriod} required type="number" placeholder="1" />
                                    </Form.Group>



                                </Modal.Body>
                                <ModalFooter>
                                    <Button variant="secondary" onClick={this.closeModal}>Cancel</Button>
                                    <Button disabled={isSubmitting} type="submit" variant="primary">Save changes</Button>
                                </ModalFooter>
                            </Form>)}
                    </Formik>
                </Modal>
            </Container>
        )
    }
}