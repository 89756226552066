import React, { useEffect } from 'react'
import { useState } from 'react'
import { Container, Table } from 'react-bootstrap';
import UptimeMonEdit from './UptimeMonEdit';

interface UptimeMonProps {

}

let UptimeMonitors = (props: UptimeMonProps) => {

    const [selectedMonitor, setSelectedMonitor] = useState(undefined);
    const [monitors, setMonitors] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);


    useEffect(() => {
        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual
        // if (!monitors) {
        getMonitors();
        //}
    }, []);

    const getMonitors = async () => {
        try {
            let results = (await (await fetch(process.env.REACT_APP_API_URL + "/api/uptime/monitors", { credentials: 'include' })).json());
            let monitors = results.endpoints.map((endpoint: any) => {
                if (endpoint.checks) {
                    endpoint.uptime = (((endpoint.checks - endpoint.failures) / endpoint.checks) * 100).toFixed(4) + "%";
                } else {
                    endpoint.uptime = "N/A";
                }
                return endpoint;
            })
            setMonitors(monitors);
        } catch (e) {
            console.error(e);
        }
    }

    let editMon = (event: any, monitor: any) => {
        event.preventDefault();
        setSelectedMonitor(monitor)
        setShowEditModal(true);
    };

    let output = monitors.map((monitor: any) => {
        return (<tr key={monitor.id}>
            <td>{monitor.name} <a href="#" onClick={function (event) { (editMon(event, monitor)) }}>(edit...)</a></td>
            <td>{monitor.endpoint}</td>
            <td>{monitor.checks}</td>
            <td>{monitor.failures}</td>
            <td>{monitor.uptime}</td>
            <td></td>
        </tr>)
    })



    return (<div>
        <Container fluid>

            <Table borderless responsive size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Endpoint</th>
                        <th>Checks</th>
                        <th>Failures</th>
                        <th>Uptime</th>
                        <th></th>
                    </tr>

                </thead>
                <tbody>
                    {output}
                </tbody>
            </Table>

        </Container>
        <UptimeMonEdit closeCBFunc={function () { setShowEditModal(false); setSelectedMonitor(undefined); }} mon={selectedMonitor} showAddBtn={true} show={showEditModal} />
    </div>)
};

export default UptimeMonitors; 