import React from 'react';
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import HealthcheckEdit from './HealthcheckEdit'
import { calulateBadgeStatus } from './HealthMonStatusBadge'
import HealthMonTableRow from './HealthMonTableRow'

type HealthcheckState = {
    healthchecks: any[],
    showEditModal: boolean,
    validated: boolean

}
type HealthcheckProps = {
    name?: string
}
class Healthchecks extends React.Component<HealthcheckProps, HealthcheckState> {
    state = { healthchecks: [], showEditModal: false, validated: false }
    //props = { name: undefined }

    constructor(props: Readonly<HealthcheckProps>) {
        super(props)
        this.hideModal = this.hideModal.bind(this);
    }

    hideModal() {
        this.setState({ showEditModal: false })
    }


    async componentDidMount() {

        let prom = await fetch(process.env.REACT_APP_API_URL + "/api/healthchecks", { credentials: 'include'});
        if (prom.status === 200) {
            let checks = await prom.json();
            let finalChecks = checks.keys.map(function (check: any) {
                check.badgeStatus = calulateBadgeStatus(check);
                return check
            });
            this.setState({ healthchecks: finalChecks });
        }
    }
    render() {
        if (!this.props.name) {
            return (<Container fluid>Hi not logged in user. Click the login button to sign up!</Container>)
        }
        let now = new Date();
        let output = this.state.healthchecks.map(function (check: any) {
            return <HealthMonTableRow check={check} key={check.id} />
        });
        return (
            <div>
                <Container fluid>

                    <Table borderless responsive size="sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>URL</th>
                                <th>Schedule</th>
                                <th>Expected Ping</th>
                                <th>Grace Period</th>
                                <th>Last Ping</th>
                            </tr>

                        </thead>
                        <tbody>
                            {output}
                        </tbody>
                    </Table>
                </Container>
                <HealthcheckEdit closeCBFunc={this.hideModal} showAddBtn={true} show={this.state.showEditModal} />
            </div>
        )
    }
}

export default Healthchecks;