import React from 'react';
import './App.css';
import 'react-app-polyfill/stable';
import AppNav from './AppNav'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Healthchecks from './Healthchecks'
import HealthcheckShow from './HealthcheckShow'
import UptimeMonitors from './UptimeMonitors'
import Integrations from './Integrations'
import Docs from './Docs';
import About from './About';
import Pricing from './Pricing';

type AppState = {
  name: string
}

class App extends React.Component<{}, AppState> {

  constructor(props: Readonly<App>) {
    super(props)
    this.state = { name: "" };
  }

  async componentDidMount() {
    try {
      var name = null;
      let user = localStorage.getItem("hc-user")
      if (user) {
        let deserial = JSON.parse(user);
        if (deserial && deserial.exp) {
          const secondsSinceEpoch = ((new Date()).getTime() / 1000)
          if (deserial.exp < secondsSinceEpoch) {
            name = null;
          } else {
            name = deserial.name;
          }
        }
      }
      if (!name) {
        let userProm = await fetch(process.env.REACT_APP_API_URL + "/api/user", { credentials: 'include'});
        if (userProm.status === 200) {
          let body = await userProm.json();
          name = body.name;
          localStorage.setItem("hc-user", JSON.stringify({ name: body.name, exp: body.exp }))
        }
      }
      this.setState({ name: name });


    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return (
      <Router>
        <AppNav name={this.state.name} />
        <Switch>
          <Route path="/pricing" component={Pricing} />
          <Route path="/about" component={About} />
          <Route path="/docs" component={Docs} />
          <Route path="/integrations" component={Integrations} />
          <Route path="/monitors/:id" component={HealthcheckShow} />
          <Route path="/monitors">
            <Healthchecks name={this.state.name} />
          </Route>
          <Route path="/uptimemon" component={UptimeMonitors} />
          <Route path="/terms">

          </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    );
  }
}

function Home(props: Readonly<{}>) {
  return (<div className="App">
    <p>
      Welcome to Healthmon. An infrastructure monitoring solutions starting with Cron Job Monitoring. We are currently working on finishing the production release of our products.
      Feel free to navigate around and try anything out for free.
    </p>
  </div>)
}

export default App;
