import Container from "react-bootstrap/Container"
import React from 'react'
import Row from "react-bootstrap/Row"
import Col from 'react-bootstrap/Col'

let Pricing = () => {
    return (
        <Container fluid>
            <Row>
                <Col md="auto">
                    <h3>Pricing</h3>
                    <p>At the time being our product offerings are free to try while we built out the feature sets and pricing packages. Feel free to try them at your leisure
                    and report any issues or suggestions you may have.
                    </p>
                </Col>
            </Row>

        </Container>
    )
}

export default Pricing