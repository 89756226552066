import moment from 'moment';
import StatusBadge from './HealthMonStatusBadge'
import { Link } from 'react-router-dom'
import React from 'react';

interface RowProps {
    check: any
    key: any
}

let HealthMonTableRow = (props: RowProps) => {
    var grace = moment.duration(props.check.gracePeriod, 'minutes')

    var lastPing = "never";
    var pingDate = "";
    if (props.check.lastPing) {
        let ping = moment(props.check.lastPing);
        lastPing = ping.fromNow();
        pingDate = "(" + ping.format() + ")"
    }
    return (<tr key={props.check.id}>
        <td><StatusBadge badgeStatus={props.check.badgeStatus} pill={false} /></td>
        <td>
            <Link to={"/monitors/" + props.check.id}>{props.check.name}</Link>
        </td>
        <td>https://ping.healthmon.io/{props.check.id}</td>
        <td>{props.check.scheduleCron}</td>
        <td>{lastPing === "never" ? "" : moment(props.check.nextExpectedPing).fromNow()}</td>
        <td>{grace.humanize()}</td>
        <td>{lastPing} {pingDate}</td>
    </tr>);
};

export default HealthMonTableRow; 